import axiosAPI from '@/services/axiosAPI';

export const miscModule = {
  namespaced: true,
  state: {},

  actions: {
    async fetchRoles() {
      const response = await axiosAPI.get('/api/misc/roles');
      return response.data.data;
    },
    async fetchOrderStatus() {
      const response = await axiosAPI.get('/api/misc/orderstatus');
      return response.data.data;
    },
    async fetchWorkOrderStatus() {
      const response = await axiosAPI.get('/api/misc/workorderstatus');
      return response.data.data;
    }
  }
};
