import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: { name: 'WorkOrders' } },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   meta: { requiresAuth: false },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "order" */ '../views/testar/Order2.vue')
  // },
  {
    path: '/historyorders',
    name: 'HistoryOrders',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "historyorders" */ '../views/admin/orders/HistoryOrders.vue')
  },
  {
    path: '/order',
    name: 'Order',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "order" */ '../views/admin/orders/Order.vue')
  },
  {
    path: '/workorders',
    name: 'WorkOrders',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "workorders" */ '../views/workorders/WO.vue')
  },
  {
    path: '/workordercomments',
    name: 'WorkOrderComments',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "workordercomments" */ '../views/workorders/WorkOrderComments.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "users" */ '../views/admin/users/Users.vue')
  },
  {
    path: '/user',
    name: 'User',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/admin/users/User.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiresVisitor: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/dayreport',
    name: 'DayReport',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dayreport" */ '../views/dayreports/DayReport.vue')
  },
  {
    path: '/dayreports',
    name: 'DayReports',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dayreports" */ '../views/dayreports/DayReports.vue')
  },
  {
    path: '/dayreportcomments',
    name: 'DayReportComments',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dayreportcomments" */ '../views/dayreports/DayReportComments.vue')
  },
  {
    path: '/historyreports',
    name: 'HistoryDayReports',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "historyreports" */ '../views/dayreports/HistoryDayReports.vue')
  },
  {
    path: '/dayreportsoverview',
    name: 'DayReportsOverview',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dayreportsoverview" */ '../views/dayreports/DayReportsOverview.vue')
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "warehouse" */ '../views/warehouse/Warehouse.vue')
  },
  // {
  //   path: '/warehousecolors',
  //   name: 'WarehouseColors',
  //   meta: { requiresAuth: true },
  //   props: true,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "warehousecolors" */ '../views/warehouse/WarehouseColors.vue')
  // },
  {
    path: '/article',
    name: 'Article',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "article" */ '../views/admin/articles/Article.vue')
  },
  {
    path: '/storagelocations',
    name: 'StorageLocations',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "storagelocations" */ '../views/admin/storagelocations/StorageLocations.vue')
  },
  {
    path: '/storagelocation',
    name: 'StorageLocation',
    meta: { requiresAuth: true },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "storagelocation" */ '../views/admin/storagelocations/StorageLocation.vue')
  },
  {
    path: '/o/:id',
    name: 'OrderInfo',
    meta: { requiresAuth: false },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "order" */ '../views/OrderInfo.vue')
  },
  // Catch all 404
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
