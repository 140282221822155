import axiosAPI from '@/services/axiosAPI';

export const chatModule = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async fetchChatMessages({ commit }, payload) {
      const response = await axiosAPI.post('/api/chat/messages', payload);
      return response.data.data;
    },
    async addChatMessage({ commit }, payload) {
      const response = await axiosAPI.post('/api/chat/message', payload);
      return response.data;
    },
    async deleteChatMessage({ commit }, payload) {
      const response = await axiosAPI.delete('/api/chat/message', {
        data: payload
      });
      return response.data;
    }
  },
  mutations: {}
};
