<template>
  <div id="app">
    <app-header />
    <router-view />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';

export default {
  name: 'App',
  components: {
    'app-header': TheHeader
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/pg-styles.scss';
</style>
