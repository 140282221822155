import axiosAPI from '@/services/axiosAPI';

export const ordersUtilsModule = {
  namespaced: true,

  actions: {
    async sendEmail({ commit }, data) {
      const response = await axiosAPI.post('/api/orders/utils/email', data);
      return response.data.data;
    },
    async fetchCustomerOrder({ commit }, payload) {
      const response = await axiosAPI.post('/api/orders/utils/customer', payload);
      return response.data.data;
    },
    async fetchOrderFiles({ commit }, payload) {
      const response = await axiosAPI.post('/api/orders/utils/files', payload);
      return response.data.data;
    }
  }
};
