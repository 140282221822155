import axiosAPI from '@/services/axiosAPI';

export const ordersModule = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async doesOrderExist({ commit }, payload) {
      const response = await axiosAPI.post('/api/orders/exist', payload);
      return response.data.data;
    },
    async fetchOrders({ commit }) {
      const response = await axiosAPI.get('/api/orders/all');
      return response.data.data;
    },
    async fetchWorkOrders({ commit }) {
      const response = await axiosAPI.get('/api/orders/allwork');
      return response.data.data;
    },
    async fetchHistoryOrders({ commit }, payload) {
      const response = await axiosAPI.post('/api/orders/allhistory', payload);
      return response.data.data;
    },
    async fetchOrder({ commit }, payload) {
      const response = await axiosAPI.post('/api/orders/one', payload);
      return response.data.data;
    },
    async addOrder({ commit }, payload) {
      const response = await axiosAPI.post('/api/orders', payload);
      return response.data;
    },
    async updateOrder({ commit }, payload) {
      const response = await axiosAPI.put('/api/orders', payload);
      return response.data;
    },
    async deleteOrder({ commit }, payload) {
      const response = await axiosAPI.delete('/api/orders', { data: payload });
      return response.data;
    }
  },
  mutations: {}
};
