import axiosAPI from '@/services/axiosAPI';

export const articlesModule = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async fetchWarehouseArticles({ commit }, payload) {
      const response = await axiosAPI.post('/api/articles/allwarehouse', payload);
      return response.data.data;
    },
    async fetchArticle({ commit }, payload) {
      const response = await axiosAPI.post('/api/articles/one', payload);
      return response.data.data;
    },
    async fetchWarehouseArticle({ commit }, payload) {
      const response = await axiosAPI.post('/api/articles/onewarehouse', payload);
      return response.data.data;
    },
    async fetchWarehouseArticleWithHistory({ commit }, payload) {
      const response = await axiosAPI.post('/api/articles/onewarehousehistory', payload);
      return response.data.data;
    },
    async addArticle({ commit }, payload) {
      const response = await axiosAPI.post('/api/articles', payload);
      return response.data;
    },
    async updateArticle({ commit }, payload) {
      const response = await axiosAPI.put('/api/articles', payload);
      return response.data;
    },
    async deleteArticle({ commit }, payload) {
      const response = await axiosAPI.delete('/api/articles', {
        data: payload
      });
      return response.data;
    }
  },
  mutations: {}
};
