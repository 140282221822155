import axiosAPI from '@/services/axiosAPI';

export const usersModule = {
  namespaced: true,
  state: {
    users: [],
    user: {}
  },

  getters: {
    allUsers: (state) => state.users,
    oneUser: (state) => state.user
  },
  actions: {
    async fetchUsers({ commit }) {
      const response = await axiosAPI.get('/api/users/all');
      return response.data.data;
    },
    async fetchUser({ commit }, data) {
      const response = await axiosAPI.post('/api/users/one', data);
      return response.data.data;
    },
    async addUser({ commit }, data) {
      const response = await axiosAPI.post('/api/users', data);
      return response.data;
    },
    async updateUser({ commit }, data) {
      let response = await axiosAPI.put('/api/users', data);
      return response.data;
    },
    async updateUserPassword({ commit }, data) {
      let response = await axiosAPI.put('/api/users/password', data);
      return response.data;
    },
    async deleteUser({ commit }, payload) {
      let response = await axiosAPI.delete('/api/users', {
        data: payload
      });
      return response.data;
    }
  },
  mutations: {
    setUsers: (state, users) => {
      state.users = users;
    },
    setUser: (state, user) => {
      state.user = user;
    }
  }
};
