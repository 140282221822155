import Vue from 'vue';
import Vuex from 'vuex';
import axiosAPI from '@/services/axiosAPI';
import { usersModule } from './modules/users';
import { usersUtilsModule } from './modules/users-utils';
import { ordersModule } from './modules/orders';
import { ordersUtilsModule } from './modules/orders-utils';
import { miscModule } from './modules/misc';
import { reportsModule } from './modules/reports';
import { reportsUtilsModule } from './modules/reports-utils';
import { chatModule } from './modules/chat';
import { articlesModule } from './modules/articles';
import { articlesUtilsModule } from './modules/articles-utils';
import { storageLocationsModule } from './modules/storage-locations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users: usersModule,
    'users-utils': usersUtilsModule,
    orders: ordersModule,
    'orders-utils': ordersUtilsModule,
    misc: miscModule,
    reports: reportsModule,
    'reports-utils': reportsUtilsModule,
    chat: chatModule,
    articles: articlesModule,
    'articles-utils': articlesUtilsModule,
    'storage-locations': storageLocationsModule
  },
  state: {
    isLoggedIn: window.localStorage.getItem('pg-is-boxicon-user-logged-in') || 'false',
    currentUser: JSON.parse(window.localStorage.getItem('pg-current-boxicon-user')) || {},
    showWarehouseHistory: window.localStorage.getItem('pg-show-warehouse-history') || 'false'
  },
  mutations: {
    setLoggedIn(state, payload) {
      window.localStorage.setItem('pg-is-boxicon-user-logged-in', payload);
      state.isLoggedIn = payload;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload;
      //window.localStorage.currentUser = JSON.stringify(payload);
      window.localStorage.setItem('pg-current-boxicon-user', JSON.stringify(payload));
    },
    setShowWarehouseHistory(state, payload) {
      window.localStorage.setItem('pg-show-warehouse-history', payload);
      state.showWarehouseHistory = payload;
    },
    setAccessToken(state, payload) {
      //  state.accessToken = payload;
      window.localStorage.setItem('pg-accessToken-boxicon', payload);
    }
  },
  actions: {
    async login(context, credentials) {
      const response = await axiosAPI.post('/api/users/login', {
        username: credentials.username,
        password: credentials.password
      });

      const accessToken = response.data.accessToken;
      if (accessToken != null && accessToken.length > 10) {
        this.commit('setLoggedIn', 'true');
        this.commit('setAccessToken', accessToken);
        this.commit('setCurrentUser', response.data.user);
      }
      return response.data;
    },
    async logout() {
      let payload = {
        dummy_id: 0
      };
      const response = await axiosAPI.delete('/api/users/logout', {
        data: payload
      });
      this.commit('setCurrentUser', {});
      this.commit('setLoggedIn', 'false');
      this.commit('setAccessToken', '');
    },
    async refreshToken2() {
      const refresh_token = '';
      const response = await axiosAPI.post('/api/refresh_token', {
        token: refresh_token
      });
      return response.data;
    },
    async refreshToken() {
      const refresh_token = '';
      const response = await axiosAPI.post('/api/users/refresh_token', {
        token: refresh_token
      });

      const accessToken = response.data.accessToken;

      if (accessToken != null && accessToken.length > 10) {
        this.commit('setLoggedIn', 'true');
        this.commit('setAccessToken', accessToken);
      }

      return response.data;
    }
  },
  getters: {
    isLoggedIn: (state) => {
      if (state.isLoggedIn == 'true') {
        return true;
      }
      return false;
    },
    isAdmin: (state) => {
      if (state.currentUser.role_id == 1) {
        return true;
      }
      return false;
    },
    isSeller: (state) => {
      if (state.currentUser.role_id == 3) {
        return true;
      }
      return false;
    },
    isWorker: (state) => {
      if (state.currentUser.role_id == 2) {
        return true;
      }
      return false;
    },
    currentUser: (state) => {
      return state.currentUser;
    },
    accessToken: (state) => {
      state.accessToken;
      return window.localStorage.getItem('pg-accessToken-boxicon');
    },
    showWarehouseHistory: (state) => {
      if (state.showWarehouseHistory == 'true') {
        return true;
      }
      return false;
    }
  }
});
