<template>
  <header>
    <div class="container">
      <input id="check" v-model="menuClose" type="checkbox" name="" />
      <div class="logo-container">
        <h3 class="logo">Boxicon</h3>
      </div>
      <div class="nav-btn">
        <div class="nav-links">
          <ul>
            <li v-if="isLoggedIn" class="nav-link" style="--i: 0.6s" @click="menuClose = !menuClose">
              <router-link :to="{ name: 'WorkOrders' }" replace exact>Arbetsordrar<i class="fas fa-caret-down"></i></router-link>
              <div class="dropdown">
                <ul>
                  <li class="dropdown-link">
                    <router-link :to="{ name: 'HistoryOrders' }" replace exact>Orderhistorik</router-link>
                  </li>
                  <div class="arrow"></div>
                </ul>
              </div>
            </li>
            <li v-if="isLoggedIn" class="nav-link" style="--i: 0.85s" @click="menuClose = !menuClose">
              <router-link :to="{ name: 'DayReports' }" replace exact>Dagsrapporter<i class="fas fa-caret-down"></i></router-link>
              <div class="dropdown">
                <ul>
                  <li class="dropdown-link">
                    <router-link :to="{ name: 'HistoryDayReports' }" replace exact>Rapporthistorik</router-link>
                  </li>
                  <li v-if="isAdmin" class="dropdown-link">
                    <router-link :to="{ name: 'DayReportsOverview' }" replace exact>Rapportstatistik</router-link>
                  </li>
                  <div class="arrow"></div>
                </ul>
              </div>
            </li>

            <li v-if="isLoggedIn" class="nav-link" style="--i: 1.1s" @click="menuClose = !menuClose">
              <router-link :to="{ name: 'Warehouse' }" replace exact>Lager</router-link>
              <!-- <div class="dropdown">
                <ul>
                  <li class="dropdown-link">
                    <router-link :to="{ name: 'WarehouseColors' }" replace exact>Färglager</router-link>
                  </li>
                  <div class="arrow"></div>
                </ul>
              </div> -->
            </li>
            <li v-if="isLoggedIn && isAdmin" class="nav-link" style="--i: 1.25s" @click="menuClose = !menuClose">
              <a href="#">Admin<i class="fas fa-caret-down"></i></a>
              <div class="dropdown">
                <ul>
                  <li class="dropdown-link">
                    <router-link :to="{ name: 'Users' }" replace exact>Användare</router-link>
                  </li>
                  <li class="dropdown-link">
                    <router-link :to="{ name: 'StorageLocations' }" replace exact>Lagerplatser</router-link>
                  </li>
                  <div class="arrow"></div>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="log-sign" style="--i: 1.8s" @click="menuClose = !menuClose">
          <a v-if="!isLoggedIn" href="#" class="btn transparent">Logga in</a>
          <label v-if="isLoggedIn" class="logged-in-as">{{ currentUser.full_name }}</label>
          <a v-if="isLoggedIn" href="#" class="btn solid" @click="logout">Logga ut</a>
        </div>
      </div>
      <div class="hamburger-menu-container" :class="{ toggle: menuClose }" @click="menuClose = !menuClose">
        <div class="hamburger-menu">
          <div></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// https://youtu.be/mBdQnQbJWHc
import { mapState } from 'vuex';
export default {
  name: 'TheHeader',

  data() {
    return {
      menuClose: false
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    ...mapState(['currentUser'])
  },

  methods: {
    async logout() {
      try {
        await this.$store.dispatch('logout');
      } catch (e) {
        // console.error(e);
        // TODO : FIX ERROR LOG
      } finally {
        this.$router.replace({
          name: 'Login'
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*
.container a.router-link-active {

color: $white;

font-weight: 800;
}

*/

header {
  font-family: 'Poppins', sans-serif;
  // position: sticky;
  top: 0px;
  background-color: $primary;

  width: 100%;
  z-index: 1000;
  height: 50px;
}

.container {
  max-width: 65rem;
  padding: 0 2rem;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.logo-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.nav-btn {
  flex: 3;
  display: flex;
}

.nav-links {
  flex: 2;
}

.log-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.logo {
  color: $gray-200;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
  line-height: 3rem;
}

.logo span {
  font-weight: 300;
}

.btn {
  display: inline-block;
  padding: 0.5rem 1.3rem;
  font-size: 0.8rem;
  border: 2px solid $gray-200;
  border-radius: 2rem;
  line-height: 1;
  margin: 0 0.2rem;
  transition: 0.3s;
  text-transform: uppercase;
}

.btn.solid,
.btn.transparent:hover {
  background-color: $gray-200;
  color: $primary;
}

.btn.transparent,
.btn.solid:hover {
  background-color: transparent;
  color: $gray-200;
}

.nav-links > ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  position: relative;
}

.nav-link > a {
  line-height: 3rem;
  color: $gray-200;
  padding: 0 0.8rem;
  letter-spacing: 1px;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
}

.nav-link > a > i {
  margin-left: 0.2rem;
}

.nav-link:hover > a {
  transform: scale(1.1);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 10rem;
  transform: translateY(10px);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

.dropdown ul {
  position: relative;
}

.dropdown-link > a {
  display: flex;
  background-color: $gray-200;
  color: $primary;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}

.dropdown-link:hover > a {
  background-color: $primary;
  color: $gray-200;
}

.dropdown-link:not(:nth-last-child(2)) {
  border-bottom: 1px solid $gray-400;
}

.dropdown-link i {
  transform: rotate(-90deg);
}

.arrow {
  position: absolute;
  width: 11px;
  height: 11px;
  top: -5.5px;
  left: 32px;
  background-color: $gray-200;
  transform: rotate(45deg);
  cursor: pointer;
  transition: 0.3s;
  z-index: -1;
}

.dropdown-link:first-child:hover ~ .arrow {
  background-color: $primary;
}

.dropdown-link {
  position: relative;
}

.dropdown.second {
  top: 0;
  left: 100%;
  padding-left: 0.8rem;
  cursor: pointer;
  transform: translateX(10px);
}

.dropdown.second .arrow {
  top: 10px;
  left: -5.5px;
}

.nav-link:hover > .dropdown,
.dropdown-link:hover > .dropdown {
  transform: translate(0, 0);
  opacity: 1;
  pointer-events: auto;
}

.hamburger-menu-container {
  flex: 1;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.hamburger-menu {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hamburger-menu div {
  width: 1.6rem;
  height: 3px;
  border-radius: 3px;
  background-color: $gray-200;
  position: relative;
  z-index: 1001;
  transition: 0.5s;
}

.hamburger-menu div:before,
.hamburger-menu div:after {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: $gray-200;
  border-radius: 3px;
  transition: 0.5s;
}

.hamburger-menu div:before {
  transform: translateY(-7px);
}

.hamburger-menu div:after {
  transform: translateY(7px);
}

#check {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  z-index: 90000;
  cursor: pointer;
  opacity: 0;
  display: none;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div {
  background-color: transparent;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:before {
  transform: translateY(0) rotate(-45deg);
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:after {
  transform: translateY(0) rotate(45deg);
}

@keyframes animation {
  from {
    opacity: 0;
    transform: translateY(15px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (max-width: 920px) {
  .hamburger-menu-container {
    display: flex;
  }

  #check {
    display: block;
  }

  .nav-btn {
    position: fixed;
    height: calc(100vh - 3rem);
    top: 3rem;
    left: 0;
    width: 100%;
    background-color: lighten($primary, 5%);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    transition: 0.65s;
  }

  #check:checked ~ .nav-btn {
    transform: translateX(0);
  }

  #check:checked ~ .nav-btn .nav-link,
  #check:checked ~ .nav-btn .log-sign {
    animation: animation 0.5s ease forwards var(--i);
  }

  .nav-links {
    flex: initial;
    width: 100%;
  }

  .nav-links > ul {
    flex-direction: column;
  }

  .nav-link {
    width: 100%;
    opacity: 0;
    transform: translateY(15px);
  }

  .nav-link > a {
    line-height: 1;
    padding: 1.6rem 2rem;
  }

  .nav-link:hover > a {
    transform: scale(1);
    background-color: $primary;
  }

  .dropdown,
  .dropdown.second {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    opacity: 1;
    pointer-events: auto;
    width: 100%;
    padding: 0;
    background-color: darken($primary, 5%);
    display: none;
  }

  .nav-link:hover > .dropdown,
  .dropdown-link:hover > .dropdown {
    display: block;
  }

  .nav-link:hover > a > i,
  .dropdown-link:hover > a > i {
    transform: rotate(360deg);
  }

  .dropdown-link > a {
    background-color: transparent;
    color: $gray-200;
    padding: 1.2rem 2rem;
    line-height: 1;
  }

  .dropdown.second .dropdown-link > a {
    padding: 1.2rem 2rem 1.2rem 3rem;
  }

  .dropdown.second .dropdown.second .dropdown-link > a {
    padding: 1.2rem 2rem 1.2rem 4rem;
  }

  .dropdown-link:not(:nth-last-child(2)) {
    border-bottom: none;
  }

  .arrow {
    z-index: 1;
    background-color: lighten($primary, 5%);
    left: 10%;
    transform: scale(1.1) rotate(45deg);
    transition: 0.5s;
  }

  .nav-link:hover .arrow {
    background-color: $primary;
  }

  .dropdown .dropdown .arrow {
    display: none;
  }

  .dropdown-link:hover > a {
    background-color: darken($primary, 10%);
  }

  .dropdown-link:first-child:hover ~ .arrow {
    background-color: $primary;
  }

  .nav-link > a > i {
    font-size: 1.1rem;
    transform: rotate(-90deg);
    transition: 0.7s;
  }

  .dropdown i {
    font-size: 1rem;
    transition: 0.7s;
  }

  .log-sign {
    flex: initial;
    width: 100%;
    padding: 1.5rem 1.9rem;
    justify-content: flex-start;
    opacity: 0;
    transform: translateY(15px);
  }

  .logged-in-as {
    opacity: 1;
  }
}
.logged-in-as {
  color: $gray-900;
  white-space: nowrap;
  margin-right: 10px;
  margin-left: 30px;
  font-size: 0.9em;
}
</style>
