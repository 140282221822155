import axiosAPI from '@/services/axiosAPI';

export const reportsModule = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async fetchDayReports({ commit }) {
      const response = await axiosAPI.get('/api/reports/alldayreports');
      return response.data.data;
    },
    async fetchHistoryDayReports({ commit }, payload) {
      const response = await axiosAPI.post('/api/reports/allhistorydayreports', payload);
      return response.data.data;
    },
    async fetchDayReport({ commit }, payload) {
      const response = await axiosAPI.post('/api/reports/onedayreport', payload);
      return response.data.data;
    },
    async addDayReport({ commit }, payload) {
      const response = await axiosAPI.post('/api/reports/dayreport', payload);
      return response.data;
    },
    async updateDayReport({ commit }, payload) {
      const response = await axiosAPI.put('/api/reports/dayreport', payload);
      return response.data;
    },
    async deleteDayReport({ commit }, payload) {
      const response = await axiosAPI.delete('/api/reports/dayreport', {
        data: payload
      });
      return response.data;
    }
  },
  mutations: {}
};
