import axiosAPI from '@/services/axiosAPI';

export const articlesUtilsModule = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async updateArticleWarehouse({ commit }, payload) {
      const response = await axiosAPI.put('/api/articles/utils/warehouse', payload);
      return response.data;
    }
  },
  mutations: {}
};
