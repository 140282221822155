import axiosAPI from '@/services/axiosAPI';

export const storageLocationsModule = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async fetchStorageLocations({ commit }) {
      const response = await axiosAPI.get('/api/storage-locations/all');
      return response.data.data;
    },
    async fetchStorageLocation({ commit }, payload) {
      const response = await axiosAPI.post('/api/storage-locations/one', payload);
      return response.data.data;
    },
    async addStorageLocation({ commit }, payload) {
      const response = await axiosAPI.post('/api/storage-locations', payload);
      return response.data;
    },
    async updateStorageLocation({ commit }, payload) {
      const response = await axiosAPI.put('/api/storage-locations', payload);
      return response.data;
    },
    async deleteStorageLocation({ commit }, payload) {
      const response = await axiosAPI.delete('/api/storage-locations', {
        data: payload
      });
      return response.data;
    }
  },
  mutations: {}
};
