import axiosAPI from '@/services/axiosAPI';

export const usersUtilsModule = {
  namespaced: true,

  actions: {
    async fetchWorkers() {
      const response = await axiosAPI.get('/api/users/utils/workers');
      return response.data.data;
    },
    async fetchUsersByRole({ commit }, payload) {
      const response = await axiosAPI.post('/api/users/utils/usersbyrole', payload);
      return response.data.data;
    }
  }
};
