import axiosAPI from '@/services/axiosAPI';

export const reportsUtilsModule = {
  namespaced: true,

  actions: {
    async fetchDayReportOverview({ commit }, data) {
      const response = await axiosAPI.post('/api/reports/utils/dayreportoverview', data);
      return response.data.data;
    }
  }
};
