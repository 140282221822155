import axios from 'axios';
import store from '../store';
import router from '../router';

// https://programmer.ink/think/axios-uses-promise-to-refresh-the-token-and-retry-the-failed-interface.html

function getLocalToken() {
  const token = window.localStorage.getItem('pg-accessToken-boxicon');
  return token;
}

// Create an axios instance
const axiosAPI = axios.create();

axiosAPI.interceptors.request.use(
  async (config) => {
    //  config.baseURL = `http://api.box.pgapp.se`;
    //   config.baseURL = `http://localhost:3000`;
    config.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;
    config.withCredentials = true;
    config.headers = {
      Authorization: `Bearer ${getLocalToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Is the marker being refreshed?
let isRefreshing = false;
// Retry queue, each item will be a function to be executed
let requests = [];

axiosAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        // Change tag status
        isRefreshing = true;
        // You must return before requesting a token function, otherwise you can retry the successful data,
        // It will not be returned to the response of the request data function
        return store
          .dispatch('refreshToken')
          .then((res) => {
            // Retry the request stored in the queue
            requests.forEach((cb) => cb());
            // Empty queue
            requests = [];
            return axiosAPI(originalRequest);
          })
          .catch(() => {
            store.dispatch('logout').then((res) => {
              router.replace({
                name: 'Login'
              });
            });
          })
          .finally(() => {
            // After the token processing is completed, the token status must be changed, otherwise the token will be re requested all the time
            isRefreshing = false;
          });
      } else {
        return new Promise((resolve) => {
          // Put the resolve into the queue, save it in a function form, and execute it directly after the token is refreshed
          requests.push(() => {
            //Add a timestamp to the url to avoid the impact of request caching
            if (originalRequest.url.indexOf('?') > -1) {
              originalRequest.url = originalRequest.url + '&n=' + new Date().getTime();
            } else {
              originalRequest.url = originalRequest.url + '?n=' + new Date().getTime();
            }
            originalRequest.headers['Authorization'] = getLocalToken();
            resolve(axiosAPI(originalRequest));
          });
        });
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAPI;
